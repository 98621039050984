import { Component, Input } from '@angular/core';
import { CiphersService } from '../../services/CiphersService/ciphers-service.service';
import { Type } from '../../models/classification-type.model';
import { Cryptanalysis } from '../../models/cryptanalysis.model';
import { RealCipher } from '../../models/real-cipher.model';
import { ActiveClassificationService } from '../../services/ActiveClassificationService/active-classification-service.service';
import { RealCiphersExpansionService } from '../../services/RealCiphersExpansion/real-ciphers-expansion.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent {
  @Input() dataToggle?: string;
  @Input() dataTarget?: string;
  classification: Type;
  realCiphers: RealCipher[];
  analyses: Array<Cryptanalysis[]>;

  constructor(
    public ciphersService: CiphersService,
    private activeClassificationService: ActiveClassificationService,
    private realCiphersExpansionService: RealCiphersExpansionService
  ) {
    this.classification = this.ciphersService
      .getClassification()
      .find((obj) => obj.name === 'Classic');
    this.realCiphers = this.ciphersService.getRealCiphers();
    this.analyses = [
      this.ciphersService
        .getCryptanalysis()
        .filter((item) => item.type === 'substitution'),
      this.ciphersService
        .getCryptanalysis()
        .filter((item) => item.type === 'transposition'),
    ];
  }

  onDontPropagateMenu(event) {
    if (this.dataTarget) {
      return;
    }

    event.stopPropagation();
  }

  openRealCiphers() {
    this.realCiphersExpansionService.openRealCiphers();
  }

  changeTab(index: number) {
    this.activeClassificationService.setSelectedTab(index);
  }
}
