import { Component, ViewChild } from '@angular/core';
import { HeaderService } from '../../components/header/header.service';
import { CiphersService } from '../../services/CiphersService/ciphers-service.service';
import { ActiveClassificationService } from '../../services/ActiveClassificationService/active-classification-service.service';
import { RealCiphersExpansionService } from '../../services/RealCiphersExpansion/real-ciphers-expansion.service';
import { Type } from '../../models/classification-type.model';
import { Subscription } from 'rxjs';
import { AccordionCardComponent } from 'src/app/components/accordion-card/accordion-card.component';

@Component({
  selector: 'app-cryptography',
  templateUrl: './cryptography.component.html',
  styleUrls: ['./cryptography.component.scss'],
})
export class CryptographyComponent {
  classification: Type[];
  selectedTabIndex: number = 0;
  private subscription: Subscription;
  @ViewChild(AccordionCardComponent) accordionCard: AccordionCardComponent;

  constructor(
    public ciphersService: CiphersService,
    private headerService: HeaderService,
    private activeClassificationService: ActiveClassificationService,
    private realCiphersExpansionService: RealCiphersExpansionService
  ) {
    headerService.showInfoPanel.next(false);
  }

  ngOnInit() {
    this.classification = this.ciphersService.getClassification();
    this.subscription = this.activeClassificationService.selectedTab$.subscribe(index => {
      this.selectedTabIndex = index;
    });

  }
  
  ngAfterViewInit() {
    if(window.location.hash === "#real-ciphers") {
      // zero timeout is to prevent error of changing property after check
      setTimeout(() => {
        this.accordionCard.openPannel()
      }, 0);
    }
    this.realCiphersExpansionService.realCiphersOpen$.subscribe(value => {
      if(value === true) {
        setTimeout(() => {
          this.accordionCard.openPannel()
        }, 0);
      }
  })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}