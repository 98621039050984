import { Component, Input } from '@angular/core';
import { Type } from '../../models/classification-type.model';
import { CiphersService } from '../../services/CiphersService/ciphers-service.service';

@Component({
  selector: 'tree-nav',
  templateUrl: './tree-nav.component.html',
  styleUrls: ['./tree-nav.component.scss'],
})
export class TreeNavComponent {
  @Input() type: Type;
  @Input() navbarSetting: boolean = false;

  constructor(public ciphersService: CiphersService) {}

  public toggleChildren(event): void {
    if(event.target.closest(".hasChildren")) {
      event.stopPropagation()
      event.target.closest(".hasChildren").classList.toggle("active")
    }
  }
}


