<li *ngFor="let subtype of type.subtypes" [ngClass]="subtype.subtypes?.length || subtype.ciphers?.length ? 'hasChildren' : ''">
  <div class="root d-flex align-items-center">
    <div class="icon-wrapper">
      <span class="icon" *ngIf="subtype.subtypes?.length || subtype.ciphers?.length">
        <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
        </svg>
      </span>
    </div>

    <div (click)="toggleChildren($event)" class="clicklable" *ngIf="subtype.routerLink" [routerLink]="subtype.routerLink">
      {{ subtype.name }}
    </div>
    <div (click)="toggleChildren($event)" class="clicklable" *ngIf="subtype.routerLink == null">
      {{ subtype.name }}
    </div>

    <svg *ngIf="subtype.subtypes?.length || subtype.ciphers?.length" class="ml-2"
      xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 320 512" stroke="#fff" fill="#fff">
      <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
      <path
        d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
    </svg>
  </div>

  <ul>
    <ng-container *ngIf="subtype.ciphers">
      <li *ngFor="let cipher of ciphersService.getCiphers(subtype.ciphers)" class="cipher ml-3">
        <a routerLink="/cryptography/cipher-types/{{cipher.slug}}-{{cipher.id}}">{{ cipher.name }}</a>
      </li>
    </ng-container>

    <tree-nav *ngIf="subtype.subtypes?.length" [type]="subtype" />
  </ul>
</li>