<ng-container>
  <div *ngIf="!dataTarget; else smallerNav">
    <div class="right-navigation">
      <div class="right-navigation-nav ml-auto">
        <div class="dropdown show">
          <div class="d-flex">
            <div class="dropdown show">
              <a class="nav-link dropdown-toggle dropdown-item" href="#" id="cryptanalysis" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Cryptanalysis
              </a>
              <div class="dropdown-menu" aria-labelledby="cryptanalysis">
                <div>
                  <a class="dropdown-item pb-1 mb-1" routerLink="/cryptanalysis" [attr.data-toggle]="dataToggle"
                    [attr.data-target]="dataTarget">What is cryptanalysis</a>
                </div>
                <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Substitution ciphers'">
                  <ul class="dropdown-nav">
                    <li *ngFor="let analysis of analyses[0]">
                      <a class="dropdown-item" [routerLink]="analysis.path" [attr.data-toggle]="dataToggle"
                        [attr.data-target]="dataTarget">{{ analysis.targetCipher }}</a>
                    </li>
                  </ul>
                </accordion-menu>

                <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Transposition ciphers'">
                  <ul class="dropdown-nav">
                    <li *ngFor="let analysis of analyses[1]">
                      <a class="dropdown-item" [routerLink]="analysis.path" [attr.data-toggle]="dataToggle"
                        [attr.data-target]="dataTarget">{{ analysis.targetCipher }}</a>
                    </li>
                  </ul>
                </accordion-menu>
              </div>
            </div>

            <div class="dropdown show">
              <a class="nav-link dropdown-toggle dropdown-item" href="#" id="cryptography" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Cryptography
              </a>
              <div class="dropdown-menu" aria-labelledby="cryptography">
                <div>
                  <a class="dropdown-item pb-1 mb-1" routerLink="/cryptography" [attr.data-toggle]="dataToggle"
                    [attr.data-target]="dataTarget">What is cryptography</a>
                </div>
                <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Cipher classifications'">
                  <ul class="dropdown-nav">
                    <li>
                      <a class="dropdown-item" (click)="changeTab(0)" routerLink="/cryptography"
                        fragment="cipher-classification" [attr.data-toggle]="dataToggle"
                        [attr.data-target]="dataTarget">Classic</a>
                    </li>
                    <li>
                      <a class="dropdown-item" (click)="changeTab(1)" routerLink="/cryptography"
                        fragment="cipher-classification" [attr.data-toggle]="dataToggle"
                        [attr.data-target]="dataTarget">By complexity</a>
                    </li>
                    <li>
                      <a class="dropdown-item" (click)="changeTab(2)" routerLink="/cryptography"
                        fragment="cipher-classification" [attr.data-toggle]="dataToggle"
                        [attr.data-target]="dataTarget">By characteristics</a>
                    </li>
                  </ul>
                </accordion-menu>
                <div>
                  <a class="dropdown-item" (click)="openRealCiphers()"  routerLink="/cryptography" fragment="real-ciphers"
                    [attr.data-toggle]="dataToggle" [attr.data-target]="dataTarget">Real Ciphers</a>
                </div>
                <div>
                  <a class="dropdown-item" routerLink="/cryptography" fragment="helpers" [attr.data-toggle]="dataToggle"
                    [attr.data-target]="dataTarget">Helpers</a>
                </div>
              </div>
            </div>
            <div>
              <a class="dropdown-item nav-link" routerLink="/about">About</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #smallerNav>
    <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Cryptanalysis'">
      <a class="dropdown-item" routerLink="/cryptanalysis" [attr.data-toggle]="dataToggle"
        [attr.data-target]="dataTarget">What is cryptanalysis</a>
      <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Substitution ciphers'">
        <ul class="dropdown-nav">
          <li *ngFor="let analysis of analyses[0]">
            <a class="dropdown-item" [routerLink]="analysis.path" [attr.data-toggle]="dataToggle"
              [attr.data-target]="dataTarget">{{ analysis.targetCipher }}</a>
          </li>
        </ul>
      </accordion-menu>

      <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Transposition ciphers'">
        <ul class="dropdown-nav">
          <li *ngFor="let analysis of analyses[1]">
            <a class="dropdown-item" [routerLink]="analysis.path" [attr.data-toggle]="dataToggle"
              [attr.data-target]="dataTarget">{{ analysis.targetCipher }}</a>
          </li>
        </ul>
      </accordion-menu>
    </accordion-menu>

    <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Cryptography'">
      <a class="dropdown-item" routerLink="/cryptography" [attr.data-toggle]="dataToggle"
        [attr.data-target]="dataTarget">What is cryptography</a>
      <accordion-menu (click)="onDontPropagateMenu($event)" [title]="'Cipher classifications'">
        <ul class="dropdown-nav">
          <li>
            <a class="dropdown-item" (click)="changeTab(0)" routerLink="/cryptography" fragment="cipher-classification"
              [attr.data-toggle]="dataToggle" [attr.data-target]="dataTarget">Classic</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="changeTab(1)" routerLink="/cryptography" fragment="cipher-classification"
              [attr.data-toggle]="dataToggle" [attr.data-target]="dataTarget">By complexity</a>
          </li>
          <li>
            <a class="dropdown-item" (click)="changeTab(2)" routerLink="/cryptography" fragment="cipher-classification"
              [attr.data-toggle]="dataToggle" [attr.data-target]="dataTarget">By characteristics</a>
          </li>
        </ul>
      </accordion-menu>
      <div>
        <a class="dropdown-item" (click)="openRealCiphers()" routerLink="/cryptography" fragment="real-ciphers" [attr.data-toggle]="dataToggle"
          [attr.data-target]="dataTarget">Real Ciphers</a>
      </div>
      <div>
        <a class="dropdown-item" routerLink="/cryptography" fragment="helpers" [attr.data-toggle]="dataToggle"
          [attr.data-target]="dataTarget">Helpers</a>
      </div>
    </accordion-menu>
    <div>
      <a class="dropdown-item nav-link" routerLink="/about" data-toggle="collapse"
        data-target=".navbar-collapse.show">About</a>
    </div>
  </ng-template>
</ng-container>