import { Injectable } from '@angular/core';
import * as classification from '../../constants/ciphers-data/classification.json';
import * as cipherTypes from '../../constants/ciphers-data/cipher-types.json';
import * as realCiphers from '../../constants/ciphers-data/real-ciphers.json';
import * as cryptanalysis from '../../constants/ciphers-data/cryptanalysis.json';
import { CipherType } from '../../models/cipher-type.model';
import { Type } from '../../models/classification-type.model';
import { Cryptanalysis } from '../../models/cryptanalysis.model';
import { RealCipher } from '../../models/real-cipher.model';

@Injectable({
  providedIn: 'root',
})
export class CiphersService {
  constructor() {}

  private realCiphers: RealCipher[] = realCiphers["default"] as RealCipher[];
  private ciphers: CipherType[] = cipherTypes["default"] as CipherType[];
  private classification: Type[] = classification["default"] as Type[];
  private cryptanalysis: Cryptanalysis[] = cryptanalysis["default"] as Cryptanalysis[];

  public getCipher(id: number): CipherType {
    return this.ciphers.find((cipher) => cipher.id == id);
  }

  public getCiphers(ids?: number[]): CipherType[] {
    if(ids) {
      return this.ciphers.filter(cipher => ids.includes(cipher.id));
    }
    return this.ciphers
  }

  public getClassification(): Type[] {
    return this.classification;
  }

  public getRealCiphers(ids?: number[]): RealCipher[] {
    if(ids) {
      return this.realCiphers.filter(cipher => ids.includes(cipher.id));
    }
    return this.realCiphers
  }

   public getCryptanalysis(ids?: number[]): Cryptanalysis[] {
    if(ids) {
      return this.cryptanalysis.filter(analysis => ids.includes(analysis.id));
    }
    return this.cryptanalysis
  }
}
