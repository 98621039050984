<div class="position-relative mode-down">
  <div class="container">
    <div class="jumbotron card py-4 py-md-5">
      <h1 class="text-center">Cryptography</h1>
      <br />
      <div class="row description-text">
        <p class="col-md-6 text-justify">
          Cryptography deals with the design and study of various methods of secret communication.
          Here you can find information about the main historical
          cipher types, and you can try some of the selected real ciphers.
        </p>
        <p class="col-md-6 text-justify">
          We can divide classical ciphers into subcategories based on various criteria. We present three types of classification for a better understanding of how ciphers work.
        </p>
      </div>
    </div>
    <div class="jumbotron" id="cipher-classification">
      <h3 class="text-center mb-4 pt-3 col-11 mx-auto">
        Cipher Classifications
      </h3>
      <mat-tab-group mat-align-tabs="center" class="border-top mt-5" [(selectedIndex)]="selectedTabIndex" dynamicHeight>
        <mat-tab *ngFor="let type of classification" label="{{ type.name }}">
          <div class="mt-5">
            <p class="description">
              <b>Description:</b> {{ type.description }}
            </p>
            <ul class="mt-4">
              <tree-nav [type]="type" />
            </ul>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="mb-5" id="real-ciphers">
      <accordion-card
        [title]="'Real ciphers'"
        [description]="
          'Here you can find real instances of historical ciphers. Choose the cipher you want to study.'
        "
        [smallBottomMargin]="true"
        *ngIf="ciphersService.getRealCiphers()"
      >
        <ul>
          <li *ngFor="let realCipher of ciphersService.getRealCiphers()">
            <a routerLink="{{ realCipher.path }}">
              {{ realCipher.name }} <span>({{ realCipher.type }})</span>
            </a>
          </li>
        </ul>
      </accordion-card>
    </div>

    <div class="jumbotron card py-4" id="helpers">
      <h3 class="text-center mb-4 pt-3">Helpers</h3>
      <div>
        <mat-card class="card-small">
          <mat-card-header>
            <div mat-card-avatar routerLink="/bellaso-method" class="pointer">
              <img src="assets/icons/helper_icon.svg" />
            </div>
            <mat-card-title routerLink="/bellaso-method" class="pointer"
              >Bellaso's Method</mat-card-title
            >
            <mat-card-subtitle routerLink="/bellaso-method" class="pointer"
              >Demostration of creating a numeric permutation from a
              phrase</mat-card-subtitle
            >
          </mat-card-header>
          <mat-card-content>
            <p>
              Many cipher types use numeric permutations as a key. However, remembering such a permutation is more problematic than remembering a meaningful text phrase. Bellaso's method is a technique used to convert a text phrase into a numeric permutation.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button routerLink="/bellaso-method">Learn More</button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div>
        <mat-card class="card-small">
          <mat-card-header>
            <div mat-card-avatar routerLink="/word-pattern-conversion" class="pointer">
              <img src="assets/icons/helper_icon.svg" />
            </div>
            <mat-card-title routerLink="/word-pattern-conversion" class="pointer"
              >Word pattern conversion</mat-card-title
            >
            <mat-card-subtitle routerLink="/word-pattern-conversion" class="pointer"
              >Various string-to-pattern conversion methods</mat-card-subtitle
            >
          </mat-card-header>
          <mat-card-content>
            <p>
              The page contains four string-to-pattern conversion methods. Each section explains detailed approach of individual methods.
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button routerLink="/word-pattern-conversion">Learn More</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
