import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  DragDropModule,
} from '@angular/cdk/drag-drop';
import { TAnagramColumns } from '../../models/anagram.model';

@Component({
  selector: 'app-movable-table',
  templateUrl: './movable-table.component.html',
  styleUrls: ['./movable-table.component.css'],
})
export class MovableTableComponent implements OnInit, OnChanges {
  @Input() public movableColumns: TAnagramColumns = [];

  columnGroups: number[][] = [];

  ngOnInit(): void {
    this.createColumnGroups();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.movableColumns = changes.movableColumns.currentValue;
    this.createColumnGroups();
  }

  public createColumnGroups() {
    this.columnGroups = this.movableColumns.reduce<number[][]>(
      (acc, currentColumn, index) => {
        if (index === 0) {
          return [[0]];
        }

        if (currentColumn.isFixed) {
          const lastGroup = acc[acc.length - 1];
          const lastGroupColumn = lastGroup[lastGroup.length - 1];

          if (this.movableColumns[lastGroupColumn].isFixed) {
            acc[acc.length - 1].push(index);
            return acc;
          }
        }

        return [...acc, [index]];
      },
      []
    );
  }

  public dropCol(event: CdkDragDrop<any, any>) {
    moveItemInArray(this.columnGroups, event.previousIndex, event.currentIndex);
  }

  public fixateIndex(value: boolean, index: number) {
    this.movableColumns[index].isFixed = value;
    this.createColumnGroups();
  }
}
