import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveClassificationService {
  private selectedTabSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectedTab$: Observable<number> = this.selectedTabSubject.asObservable();

  constructor() { }

  setSelectedTab(index: number) {
    this.selectedTabSubject.next(index);
  }
}
