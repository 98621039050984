<div class="fixed" id="header">
    <nav class="navbar main-navbar navbar-expand-lg navbar-dark bg-dark bg-navbar-blue" id="mainNav"
        [@scrollAnimation]="navHeaderVisible">
        <div class="container">
            <div class="d-flex w-100 align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <a href="https://hcportal.eu/index.html">
                        <img class="mr-3 mr-sm-5" src="../../../assets/img/HCPortal.png" alt />
                    </a>
                    <a class="navbar-brand" href="#">Education</a>
                </div>

                <div>
                    <div *ngIf="!isSmallerNav">
                        <app-navigation></app-navigation>
                    </div>
    
                    <div *ngIf="isSmallerNav">
                        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                            data-target="#navResponsiveCollapse" aria-controls="navResponsiveCollapse" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <div *ngIf="isSmallerNav" class="collapse navbar-collapse" id="navResponsiveCollapse">
        <div class="bg-navbar-blue p-4">
            <app-navigation [isDivider]="false" [dataToggle]="'collapse'" [dataTarget]="'.navbar-collapse.show'">
            </app-navigation>
        </div>
    </div>

    <div *ngIf="showInfoPanel" class="cipher-navbar navbar navbar-expand-lg navbar-light bg-navbar-blue">
        <div class="d-flex flex-column offset-md-1">
            <div class="cipher-type">
                <h5>{{ cipherType }}</h5>
            </div>
            <div class="cipher-name">
                <h4>{{ cipherName }}</h4>
            </div>
        </div>
    </div>
</div>