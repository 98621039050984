import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';


@Component({
  selector: 'accordion-card',
  templateUrl: './accordion-card.component.html',
  styleUrls: ['./accordion-card.component.css'],
})
export class AccordionCardComponent implements OnInit, OnDestroy {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public bottomMargin: boolean = false;
  @Input() public smallBottomMargin: boolean = false;
  @ViewChild(MatExpansionPanel) pannel?: MatExpansionPanel; 
  panelOpenState = false;

  headerStyle = {
    paddingTop: '16px',
    paddingBottom: '16px',
  };

  ngOnInit() {}

  ngOnDestroy(): void {}

  openPannel() { 
    if(!this.pannel) { return }
    this.pannel.open();
  }
}
