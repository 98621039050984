import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CiphersService } from '../../services/CiphersService/ciphers-service.service';
import { HeaderService } from '../../components/header/header.service';
import { Type } from '../../models/classification-type.model';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class Home implements OnInit {
  id: number;
  classification: Type[];

  constructor(
    private route: ActivatedRoute,
    private headerService: HeaderService,
    public ciphersService: CiphersService,
  ) {
    this.headerService.showInfoPanel.next(false);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.classification = this.ciphersService.getClassification();
  }
}
