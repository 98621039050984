<div class="position-relative mode-down">
  <div class="container">
    <div class="jumbotron card pb-4 pt-4 pb-md-5 pt-md-5">
      <h1 class="text-center">Welcome to the HCPortal Education module.</h1>
      <br />
      <div class="row description-text">
        <p class="col-md-6 text-justify">
          The main purpose of the Education modul is to provide a collection of interactive tools with graphical
          visualization of the data designed for a better understanding of historical ciphers and their cryptanalysis.
        </p>
        <p class="col-md-6 text-justify">
            Before you start studying, please head to our <u routerLink="about" class="pointer font-weight-bolder">about page</u> where you can find some useful information for a better understanding of the structure of our education modul.
        </p>
      </div>
    </div>

    <h3 class="text-center mb-4 pt-3 col-11 mx-auto">What are you interested in?</h3>
    <div class="row mb-5 pb-5">
      <div class="col-lg-6">
        <mat-card class="card-small pointer" routerLink="cryptanalysis">
          <div>
            <mat-card-title>Cryptanalysis</mat-card-title>
            <mat-card-subtitle>Interactive tools of cryptanalysis</mat-card-subtitle>
          </div>
          <mat-card-content>
            <p class="text-justify">Here you can select one of the available classical ciphers and their respective cryptanalytic technique. Each technique is accompanied with a visualization of used data for its better understanding.</p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button routerLink="cryptanalysis">
              Learn More
            </button>
          </mat-card-actions>
        </mat-card>
      </div>

      <div class="col-lg-6">
        <mat-card class="card-small pointer" routerLink="cryptography">
          <div>
            <mat-card-title>Cryptography</mat-card-title>
            <mat-card-subtitle>Cipher classification, real ciphers, helpers</mat-card-subtitle>
          </div>
          <mat-card-content>
            <p class="text-justify">Here you can find information about the main types of historical ciphers. You can try to encrypt and decrypt your own message with real ciphers. We also provide some useful cryptographic tools.</p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button routerLink="cryptography">
              Learn More
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
