<app-side-navbar [sideMenu]="[{title: 'General description of steganography', id: 'intro'}]" class="d-block">
    <div class="container">
        <mat-card id="intro" class="col-md-12 info-mat-card">
            <mat-card-header>
                <mat-card-title>General description of steganography</mat-card-title>
            </mat-card-header>
            <mat-card-content style="word-wrap: break-word">
                <p>
                    Steganography is a scientific discipline that deals with the
                    concealment of information in such a way as to make it invisible to
                    those who are not supposed to have access to it. The information is
                    hidden within another message or physical object that is visible and
                    we call it a carrier. <br />
                    If you want to know more about how we can divide steganography into
                    categories, see the <a (click)="changeTab(2)" routerLink="/cryptography"
                        fragment="cipher-classification">classification by characteristics</a>.
                </p>

                <p>
                    References: <br />O. Grošek, M. Vojvoda, P. Zajac : Klasické šifry.
                    STU 2007.
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</app-side-navbar>