import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CiphersService } from '../../../services/CiphersService/ciphers-service.service';
import { CipherType } from '../../../models/cipher-type.model';
import { HeaderService } from '../../../components/header/header.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-view-cipher',
  templateUrl: './view-cipher.component.html',
  styleUrls: ['../../../app.component.css', './view-cipher.component.scss'],
})
export class ViewCipherComponent {
  cipherData: CipherType;
  sideMenuData: any;
  public lightboxImageIndex: number = 0;
  private _album: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public ciphersService: CiphersService,
    private headerService: HeaderService,
    private _lightbox: Lightbox
  ) {
    headerService.showInfoPanel.next(true);
    headerService.cipherType.next('General description of');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      this.cipherData = this.ciphersService.getCipher(
        this.getIdfromSlug(data.slug_id)
      );
      this.headerService.cipherName.next(this.cipherData.name);
      this.sideMenuData = this.getSideMenuData();

      // get images into one array for lightbox
      this.cipherData.data?.examples?.forEach((cipher, index) => {
        if (cipher.images) {
          cipher.images.forEach((image) => {
            if (this._album?.[index]) {
              this._album[index].push({ src: image.path });
            } else {
              this._album[index] = [{ src: image.path }];
            }
          });
        }
      });
    });
  }

  private getIdfromSlug(slug: string): number {
    const slugParts = slug.split('-');
    return parseFloat(slugParts[slugParts.length - 1]);
  }

  open(arrayIndex: number, index: number): void {
    this._lightbox.open(this._album[arrayIndex], index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  public getLightBoxIndex(): number {
    this.lightboxImageIndex += 1;
    return this.lightboxImageIndex;
  }

  private makeSlug(title: string): string {
    return title.toLowerCase().replace(/\s+/g, '-');
  }

  public getSideMenuData(): any {
    if (this.cipherData?.data) {
      const sideMenuData = [
        {
          title: this.cipherData.data.general.title,
          id: 'intro',
        },
      ];

      this.cipherData.data?.examples?.forEach((cipherExample) => {
        sideMenuData.push({
          title: cipherExample.title,
          id: this.makeSlug(cipherExample.title),
        });
      });

      if (this.cipherData.data.realCiphers) {
        sideMenuData.push({
          title: 'Real ciphers',
          id: 'real-ciphers',
        });
      }

      if (this.cipherData.data.cryptanalysis) {
        sideMenuData.push({
          title: 'Cryptanalysis',
          id: 'cryptanalysis-card',
        });
      }

      return sideMenuData;
    }

    return null;
  }
}
