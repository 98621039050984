import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RealCiphersExpansionService {
  private realCiphersOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  realCiphersOpen$: Observable<boolean> = this.realCiphersOpenSubject.asObservable();

  constructor() { }

  openRealCiphers() {
    this.realCiphersOpenSubject.next(true);
  }
}
