<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle
        [ngClass]="{'bottom-margin': bottomMargin, 'small-bottom-margin': smallBottomMargin, 'no-margin':!bottomMargin && !smallBottomMargin}">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" [style]="headerStyle">
            <div header class="wrapper">
                <mat-panel-title>
                    <div class="accordion-title">{{title}}</div>
                </mat-panel-title>
                <mat-panel-description>
                    <div class="accordion-description">{{description}}</div>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>
        <ng-content> </ng-content>
    </mat-expansion-panel>
</mat-accordion>