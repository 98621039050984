import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { TableComponent } from '../components/table/table.component';
import { CommonModule } from '@angular/common';
import { GraphComponent } from '../components/graph/graph.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { KatexModule } from 'ng-katex';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
// import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SideNavbarComponent } from '../components/side-navbar/side-navbar.component';
import { MovableTableComponent } from '../components/movable-table/movable-table.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
@NgModule({
  imports: [
    DragDropModule,
    MatTableModule,
    MatSortModule,
    CommonModule,
    HighchartsChartModule,
    KatexModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    CdkStepperModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  declarations: [
    TableComponent,
    GraphComponent,
    SideNavbarComponent,
    MovableTableComponent,
  ],
  exports: [
    DragDropModule,
    MatTableModule,
    MatSortModule,
    TableComponent,
    GraphComponent,
    SideNavbarComponent,
    MovableTableComponent,
    HighchartsChartModule,
    KatexModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    CdkStepperModule,
    MatStepperModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
})
export class SharedModule {}
