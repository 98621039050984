<div class="position-relative mode-down">
  <div class="container">
    <div class="jumbotron card pb-4 pt-4 pb-md-5 pt-md-5">
      <h1 class="text-center">Cryptanalysis</h1>
      <br />
      <div class="row description-text">
        <p class="col-md-6 text-justify">
          Cryptanalysis is the science concerned with methods of obtaining
          the contents of encrypted information without access to the
          secret information (cipher key) that is normally needed for decryption.
          Cryptanalysis is actually the opposite of cryptography,
          which creates ciphers.
        </p>
        <p class="col-md-6 text-justify">
          When you are interested in cryptanalysis decryption here you can find
          some classical ciphers cryptanalytic techniques. Each technique is
          accompanied with a visualization of used data for its better
          understanding.
        </p>
      </div>
    </div>

    <div class="cipher-cryptanalysis">
      <accordion-card
        [title]="'Substitution ciphers'"
        [description]="''"
        [smallBottomMargin]="true"
      >
        <mat-card class="card-small" *ngFor="let analysis of analyses[0]">
          <mat-card-header>
            <div
              mat-card-avatar
              [routerLink]="analysis.path"
              class="pointer"
            >
              <img [src]="analysis.icon" />
            </div>
            <mat-card-title
              [routerLink]="analysis.path"
              class="pointer"
              >
              {{analysis.targetCipher}}
            </mat-card-title>
            <mat-card-subtitle
              [routerLink]="analysis.path"
              class="pointer"
              >
              {{analysis.attackName}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{analysis.description}}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button [routerLink]="analysis.path">
              Learn More
            </button>
          </mat-card-actions>
        </mat-card>
      </accordion-card>

      <accordion-card [title]="'Transposition ciphers'" [description]="''">
                <mat-card class="card-small" *ngFor="let analysis of analyses[1]">
          <mat-card-header>
            <div
              mat-card-avatar
              [routerLink]="analysis.path"
              class="pointer"
            >
              <img [src]="analysis.icon" />
            </div>
            <mat-card-title
              [routerLink]="analysis.path"
              class="pointer"
              >
              {{analysis.targetCipher}}
            </mat-card-title>
            <mat-card-subtitle
              [routerLink]="analysis.path"
              class="pointer"
              >
              {{analysis.attackName}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{analysis.description}}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button [routerLink]="analysis.path">
              Learn More
            </button>
          </mat-card-actions>
        </mat-card>
      </accordion-card>
    </div>
  </div>
</div>
