<footer class="main-footer">
    <nav>
      <ul class="main-footer__links">
        <li class="main-footer__link">
          <a routerLink="/about">About</a>
        </li>
        <li class="main-footer__link">
          <a routerLink="/about">      © 2020-{{currentDate}} HCPortal team</a>

        </li>
      </ul>
    </nav>
  </footer>
