import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'accordion-menu',
  templateUrl: './accordion-menu.component.html',
  styleUrls: ['./accordion-menu.component.css'],
})
export class AccordionMenuComponent implements OnInit, OnDestroy {
  @Input() title: string;
  panelOpenState = false;

  style = {
    background: 'none',
    color: 'white',
    boxShadow: 'none',
    paddingTop: 4,
    paddingBottom: 4,
  };
  headerStyle = {
    paddingTop: '16px',
    paddingBottom: '16px',
  };

  ngOnInit() {}

  onDontPropagate(event) {
    event.stopPropagation();
  }

  ngOnDestroy(): void {}
}
