<div [ngClass]="fullWidth?'full-width mat-elevation-z8':''">
    <table mat-table #sort="matSort" [dataSource]="dataSource" matSort [matSortActive]="defaultSort.sortByColumn"
        [matSortDirection]="defaultSort.order" class="">

        <ng-container *ngFor="let name of columnNames" mat-text-column="end" [matColumnDef]="name"
            [sticky]="isStickyStart(name)" [stickyEnd]="isStickyEnd(name)">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{name}}</th>
            <td mat-cell *matCellDef="let element">{{element[name] }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames; sticky: stickyColumns?.stickyHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: columnNames; let i = index" (click)="onClick(i)"></tr>
    </table>
</div>