<app-side-navbar *ngIf="this.cipherData?.data; else noContent" [sideMenu]="this.getSideMenuData()" class="d-block">
  <div class="container" >
    <mat-card id="intro" class="col-md-12 info-mat-card">
      <mat-card-header>
        <mat-card-title>
          {{ this.cipherData.data.general.title }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="word-wrap: break-word">
        <p [innerHTML]="this.cipherData.data.general.description"></p>

        References: <br />
        <p [innerHTML]="this.cipherData.data.general.reference"></p>
      </mat-card-content>
    </mat-card>

    <mat-card
      *ngFor="let cipher of this.cipherData.data.examples; let i = index"
      id="{{ this.makeSlug(cipher.title) }}"
      class="col-md-12 info-mat-card"
    >
      <mat-card-header>
        <mat-card-title>{{ cipher.title }}</mat-card-title>
      </mat-card-header>

      <mat-card-content style="word-wrap: break-word">
        <p [innerHTML]="cipher.description"></p>
        <div *ngIf="cipher.images">
          <br />
          <div class="d-md-flex flex-wrap">
            <img
              *ngFor="let image of cipher.images; let j = index"
              (click)="open(i, j)"
              [ngStyle]="{
                height: image.height ?? 'auto',
                width: image?.width
              }"
              [src]="image.path"
              [alt]="image.alt"
              class="center mt-2 rounded border"
            />
          </div>
          <br />
        </div>

        <div class="mt-4" *ngIf="cipher.weakness">
          <mat-card-header>
            <mat-card-title>Weaknesses and security analysis</mat-card-title>
          </mat-card-header>

          <p
            *ngIf="cipher.weakness.description"
            [innerHTML]="cipher.weakness.description"
          ></p>
          <div *ngFor="let item of cipher.weakness.items">
            <strong>{{ item.title }}</strong>
            <p [innerHTML]="item.description"></p>
          </div>
        </div>

        <div *ngIf="cipher.cryptography" class="mt-4">
          <mat-card-header>
            <mat-card-title>{{ cipher.cryptography.title }}</mat-card-title>
          </mat-card-header>
          <p
            *ngIf="cipher.cryptography.description"
            [innerHTML]="cipher.cryptography.description"
          ></p>

          <div *ngFor="let item of cipher.cryptography.items" class="mb-3">
            <strong>{{ item.title }}</strong>
            <p [innerHTML]="item.description"></p>
            <h5><ng-katex [equation]="item.equation"></ng-katex></h5>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card
      *ngIf="this.cipherData.data.realCiphers"
      id="real-ciphers"
      class="col-md-12 info-mat-card"
    >
      <mat-card-header class="d-flex align-items-center">
        <mat-card-title>Real ciphers</mat-card-title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mb-2"
          height="16"
          width="14"
          viewBox="0 0 448 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M160 64c0-8.8 7.2-16 16-16s16 7.2 16 16V200c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c8.8 0 16 7.2 16 16c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c7.8 0 14.3 5.6 15.7 13c1.6 8.2 7.3 15.1 15.1 18s16.7 1.6 23.3-3.6c2.7-2.1 6.1-3.4 9.9-3.4c8.8 0 16 7.2 16 16l0 16V392c0 39.8-32.2 72-72 72H272 212.3h-.9c-37.4 0-72.4-18.7-93.2-49.9L50.7 312.9c-4.9-7.4-2.9-17.3 4.4-22.2s17.3-2.9 22.2 4.4L116 353.2c5.9 8.8 16.8 12.7 26.9 9.7s17-12.4 17-23V320 64zM176 0c-35.3 0-64 28.7-64 64V261.7C91.2 238 55.5 232.8 28.5 250.7C-.9 270.4-8.9 310.1 10.8 339.5L78.3 440.8c29.7 44.5 79.6 71.2 133.1 71.2h.9H272h56c66.3 0 120-53.7 120-120V288l0-16c0-35.3-28.7-64-64-64c-4.5 0-8.8 .5-13 1.3c-11.7-15.4-30.2-25.3-51-25.3c-6.9 0-13.5 1.1-19.7 3.1C288.7 170.7 269.6 160 248 160c-2.7 0-5.4 .2-8 .5V64c0-35.3-28.7-64-64-64zm48 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z"
          />
        </svg>
      </mat-card-header>


      <mat-card-content style="word-wrap: break-word">
        <p>Here you can choose a real cipher and interact with the process of encryption/decryption.</p>
        <ul>
          <li *ngFor=" let realCipher of ciphersService.getRealCiphers(this.cipherData.data.realCiphers)">
            <a routerLink="{{ realCipher.path }}">
              {{ realCipher.name }} <span>({{ realCipher.type }})</span>
            </a>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>

    <mat-card
      *ngIf="this.cipherData.data.cryptanalysis"
      id="cryptanalysis-card"
      class="col-md-12 info-mat-card"
    >
      <mat-card-header class="d-flex align-items-center">
        <mat-card-title>Cryptanalysis</mat-card-title>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mb-2"
          height="16"
          width="14"
          viewBox="0 0 448 512"
        >
          <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M160 64c0-8.8 7.2-16 16-16s16 7.2 16 16V200c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c8.8 0 16 7.2 16 16c0 10.3 6.6 19.5 16.4 22.8s20.6-.1 26.8-8.3c3-3.9 7.6-6.4 12.8-6.4c7.8 0 14.3 5.6 15.7 13c1.6 8.2 7.3 15.1 15.1 18s16.7 1.6 23.3-3.6c2.7-2.1 6.1-3.4 9.9-3.4c8.8 0 16 7.2 16 16l0 16V392c0 39.8-32.2 72-72 72H272 212.3h-.9c-37.4 0-72.4-18.7-93.2-49.9L50.7 312.9c-4.9-7.4-2.9-17.3 4.4-22.2s17.3-2.9 22.2 4.4L116 353.2c5.9 8.8 16.8 12.7 26.9 9.7s17-12.4 17-23V320 64zM176 0c-35.3 0-64 28.7-64 64V261.7C91.2 238 55.5 232.8 28.5 250.7C-.9 270.4-8.9 310.1 10.8 339.5L78.3 440.8c29.7 44.5 79.6 71.2 133.1 71.2h.9H272h56c66.3 0 120-53.7 120-120V288l0-16c0-35.3-28.7-64-64-64c-4.5 0-8.8 .5-13 1.3c-11.7-15.4-30.2-25.3-51-25.3c-6.9 0-13.5 1.1-19.7 3.1C288.7 170.7 269.6 160 248 160c-2.7 0-5.4 .2-8 .5V64c0-35.3-28.7-64-64-64zm48 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304zm48-16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V304z"
          />
        </svg>
      </mat-card-header>


      <mat-card-content style="word-wrap: break-word">
        <p>Here you can choose a cryptanalysis method and interact with the process of decryption.</p>
        <ul>
          <li *ngFor=" let analysis of ciphersService.getCryptanalysis(this.cipherData.data.cryptanalysis)">
            <a routerLink="{{ analysis.path }}">
              {{ analysis.attackName }} <span>({{ analysis.targetCipher }})</span>
            </a>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</app-side-navbar>

<ng-template #noContent>
  <div class="alert alert-primary no-content" role="alert">
    This section is still waiting for content to be added.
  </div>
</ng-template>
