import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Home } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { SteganographyComponent } from './pages/steganography/steganography.component';
import { CryptographyComponent } from './pages/cryptography/cryptography.component';
import { ViewCipherComponent } from './pages/cipher-types/view-cipher/view-cipher.component';
import { CryptanalysisComponent } from './pages/cryptanalysis/cryptanalysis.component';


const routes: Routes = [
  // GENERAL 
  { path: '', component: Home },
  { path: 'home', component: Home },
  { path: 'about', component: AboutComponent },
  { path: 'cryptography', component: CryptographyComponent},
  { path: 'cryptography/steganography', component: SteganographyComponent },
  { path: 'cryptography/cipher-types/:slug_id', component: ViewCipherComponent },
  { path: 'cryptanalysis', component: CryptanalysisComponent },

  // REAL CIPHERS 
  {
    path: 'real-ciphers/iroha',
    loadChildren: () =>
      import('./pages/iroha-component-module/iroha.module').then(
        (m) => m.IrohaModule
      ),
  },
  {
    path: 'real-ciphers/jn25',
    loadChildren: () =>
      import('./pages/jn25-component-module/jn25.module').then(
        (m) => m.Jn25Module
      ),
  },
  {
    path: 'real-ciphers/cipher-from-futurama',
    loadChildren: () =>
      import('./pages/futurama-module/futurama.module').then(
        (m) => m.FuturamaModule
      ),
  },
  {
    path: 'real-ciphers/cipher-from-batman',
    loadChildren: () =>
      import('./pages/batman-module/batman.module').then(
        (m) => m.BatmanModule
      ),
  },
  {
    path: 'real-ciphers/cipher-from-martian',
    loadChildren: () =>
      import('./pages/martian-ascii-module/martian-ascii.module').then(
        (m) => m.MartianAsciiModule
      ),
  },
  {
    path: 'real-ciphers/cipher-from-mrrobot',
    loadChildren: () =>
      import('./pages/mr-robot-rot13-module/mr-robot-rot13.module').then(
        (m) => m.MrRobotRot13Module
      ),
  },

  // CRYPTANALYSIS PAGES
  {
    path: 'cryptanalysis/caesar-cipher',
    loadChildren: () =>
      import('./pages/caesar-cipher-module/caesar-cipher.module').then(
        (m) => m.CaesarCipherModule
      ),
  },
  {
    path: 'cryptanalysis/polyalphabetic-cipher',
    loadChildren: () =>
      import(
        './pages/polyalphabetic-cipher-module/polyalphabetic-cipher.module'
      ).then((m) => m.PolyalphCipherModule),
  },
  {
    path: 'cryptanalysis/monoalphabetic-cipher',
    loadChildren: () =>
      import(
        './pages/monoalphabetic-cipher-module/monoalphabetic-cipher.module'
      ).then((m) => m.MonoalphCipherModule),
  },
  {
    path: 'cryptanalysis/scytale',
    loadChildren: () =>
      import('./pages/scytale-module/scytale.module').then(
        (m) => m.ScytaleModule
      ),
  },
  {
    path: 'cryptanalysis/permutation-transposition',
    loadChildren: () =>
      import(
        './pages/permutation-transposition-module/permutation-transposition.module'
      ).then((m) => m.PermutationTranspositionModule),
  },
  {
    path: 'cryptanalysis/anagram-method',
    loadChildren: () =>
      import('./pages/anagram-method-module/anagram-method.module').then(
        (m) => m.AnagramMethodModule
      ),
  },
  {
    path: 'cryptanalysis/masc-dictionary-attack',
    loadChildren: () =>
      import('./pages/dictionary-module/dictionary.module').then(
        (m) => m.DictionaryModule
      ),
  },
  {
    path: 'cryptanalysis/autokey-to-vigenere',
    loadChildren: () =>
      import('./pages/vigenere-transfromation-module/vigenere-transformation.module').then(
        (m) => m.VigenereTransfromationModule
      ),
  },
  
  // HELPERS 
  {
    path: 'bellaso-method',
    loadChildren: () =>
      import('./pages/bellaso-method-module/bellaso-method.module').then(
        (m) => m.BellasoMethodModule
      ),
  },
  {
    path: 'word-pattern-conversion',
    loadChildren: () =>
      import('./pages/pattern-help-module/pattern-help.module').then(
        (m) => m.PatternHelpModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 120]
    }),
  ],
  exports: [RouterModule],
})


export class AppRoutingModule {}
