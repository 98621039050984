<table class="full-width">
    <thead>
        <tr class="table-row" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="columnGroups"
            (cdkDropListDropped)="dropCol($event)">
            <th class="header-cell grabbable cell" *ngFor="let columnGroup of columnGroups"
                [attr.colspan]="columnGroup.length" cdkDrag>
                <div class="column-wrapper">
                    <div class="header-column" *ngFor="let i of columnGroup">
                        {{movableColumns[i].pos}}
                    </div>
                </div>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr class="table-row">
            <ng-container *ngFor="let columnGroup of columnGroups">
                <td class="movable-td cell" *ngFor="let columnIndex of columnGroup">
                    <ng-container *ngFor="let row of movableColumns[columnIndex].rows">

                        <div [ngStyle]="{'color': (row.showColor && row.color ) ? row.color : 'none',
                                      'font-weight': (row.showColor && row.color) ? 'bold' : 'normal'}">
                            {{row.char}}</div>
                    </ng-container>

                </td>
            </ng-container>
        </tr>

        <tr class="table-row">
            <ng-container *ngFor="let columnGroup of columnGroups">
                <td class="checkbox-cell" *ngFor="let i of columnGroup">
                    <mat-checkbox color="primary" (change)="fixateIndex($event.checked, i)"
                        [checked]="!!movableColumns[i].isFixed"></mat-checkbox>
                </td>
            </ng-container>
    </tbody>
</table>