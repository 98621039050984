<div class="position-relative mode-down about-page">
  <div class="container">
    <div class="jumbotron card pb-4 pt-4 pb-md-5 pt-md-5">
      <h1 class="text-center">About education page</h1>
      <br />
      <div class="row description-text">
        <p class=" text-justify">
          The goal of this website is to aid the students of classical ciphers courses. It provides selected tools
          to demonstrate historical cryptography and cryptanalytic techniques.
          These tools are supplemented with graphical visualisation of the data for a better understanding.
        </p>
      </div>
    </div>
  
    <mat-card class="p-3 p-md-4 p-lg-5 card-small">
      <mat-card-header>
        <mat-card-title>Page navigation</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          The cards are distinguished by the color of the left border.
        </p>
  
        <mat-card class="p-4 info-mat-card">
          <mat-card-header>
            <mat-card-title>Info card</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              Here you can find information about the cipher, how the algorithm works, ...
            </p>
          </mat-card-content>
        </mat-card>
  
        <mat-card class="p-4 action-mat-card">
          <mat-card-header>
            <mat-card-title>Action card</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              Here you can set up input parameters, ...
            </p>
          </mat-card-content>
        </mat-card>
  
        <mat-card class="p-4 computed-mat-card">
          <mat-card-header>
            <mat-card-title>Results card</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              Here you can see data, computed results, result of the cryptanalysis, ...
            </p>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</div>