import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
// import { Routes, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module'; // CLI imports AppRoutingModule

import { HeaderService } from './components/header/header.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AccordionMenuComponent } from './components/accordion-menu/accordion-menu.component';
import { AccordionCardComponent } from './components/accordion-card/accordion-card.component';
import { TreeNavComponent } from './components/tree-nav/tree-nav.component';
import { SharedModule } from './shared/shared.module';
import { Home } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { CryptographyComponent } from './pages/cryptography/cryptography.component';
import { CryptanalysisComponent } from './pages/cryptanalysis/cryptanalysis.component';

 import { MatTabsModule } from '@angular/material/tabs';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewCipherComponent } from './pages/cipher-types/view-cipher/view-cipher.component';
import { LightboxModule } from 'ngx-lightbox';
import { SteganographyComponent } from './pages/steganography/steganography.component';

@NgModule({
  declarations: [
    AppComponent,
    AccordionMenuComponent,
    AccordionCardComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    Home,
    AboutComponent,
    CryptographyComponent,
    CryptanalysisComponent,
    TreeNavComponent,
    ViewCipherComponent,
    SteganographyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    MatInputModule,
    BrowserAnimationsModule,
    FormsModule,
    HighchartsChartModule,
    SharedModule,
    NgbModule,
    DragDropModule,
    LightboxModule,
  ],
  providers: [HeaderService],
  bootstrap: [AppComponent],
})
export class AppModule {}
