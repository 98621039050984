import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';

import { HeaderService } from './header.service';
import { Subscription } from 'rxjs';
import {
  style,
  trigger,
  state,
  transition,
  animate,
} from '@angular/animations';
import { NavigationStart, Router } from '@angular/router';

export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden',
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state(
        VisibilityState.Visible,
        style({
          transform: 'translateY(0)',
        })
      ),
      state(
        VisibilityState.Hidden,
        style({
          transform: 'translateY(-154px)',
          height: 0,
          padding: 0,
        })
      ),
      transition(
        `${VisibilityState.Visible} => ${VisibilityState.Hidden}`,
        animate('100ms')
      ),
      transition(
        `${VisibilityState.Hidden} => ${VisibilityState.Visible}`,
        animate('10ms')
      ),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  cipherName: string;
  cipherNameSubscription: Subscription;
  cipherType: string;
  cipherTypeSubscription: Subscription;
  showInfoPanel: boolean;
  showInfoPanelSubscription: Subscription;

  navHeaderVisible = VisibilityState.Visible;
  infoHeaderVisible = VisibilityState.Hidden;

  isSmallerNav = false;

  constructor(private headerService: HeaderService, private router: Router) {
    this.cipherNameSubscription = headerService.cipherName.subscribe(
      (value) => {
        this.cipherName = value;
      }
    );

    this.cipherTypeSubscription = headerService.cipherType.subscribe(
      (value) => {
        this.cipherType = value;
      }
    );

    this.showInfoPanelSubscription = headerService.showInfoPanel.subscribe(
      (value) => {
        this.showInfoPanel = value;
      }
    );

    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.navHeaderVisible = VisibilityState.Visible;
      }
    });
  }

  ngOnInit() {
    this.infoHeaderVisible = this.showInfoPanel
      ? VisibilityState.Visible
      : VisibilityState.Hidden;

    this.onResize();
  }

  @HostListener('window:scroll')
  checkScroll() {
    if (!this.showInfoPanel) {
      return;
    }

    if (window.scrollY >= 50) {
      this.navHeaderVisible = VisibilityState.Hidden;
    } else {
      this.navHeaderVisible = VisibilityState.Visible;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.isSmallerNav = window.innerWidth < 992;
  }

  onDontPropagateMenu(event) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.cipherNameSubscription.unsubscribe();
    this.cipherTypeSubscription.unsubscribe();
    this.showInfoPanelSubscription.unsubscribe();
  }
}
