<mat-sidenav-container class="col-md-12 col-xl-10 offset-xl-1">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    [fixedInViewport]="true"
    class="col-lg-2 col-md-12 side-nav"
    [style.margin-top.px]="topGap + 52"
  >
    <ol>
      <li
        class="btn-side-navbar col-md-12"
        (click)="scroll(menu, i)"
        *ngFor="let menu of sideMenu; let i = index"
        [class.active]="selectedIndex === i"
      >
        <a
          ><span>{{ menu.title }}</span></a
        >
      </li>
    </ol>
  </mat-sidenav>
  <mat-sidenav-content
    [style.margin-top.px]="topGap + 20"
    class="content remove-left-margin col-md-10 col-xl-10"
  >
    <ng-content> </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
