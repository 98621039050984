import { Component } from '@angular/core';
import { HeaderService } from '../../components/header/header.service';
import { CiphersService } from '../../services/CiphersService/ciphers-service.service';
import { Cryptanalysis } from '../../models/cryptanalysis.model';

@Component({
  selector: 'app-cryptanalysis',
  templateUrl: './cryptanalysis.component.html',
  styleUrls: ['./cryptanalysis.component.css'],
})
export class CryptanalysisComponent {
  analyses: Array<Cryptanalysis[]>;

  constructor(
    private headerService: HeaderService,
    private ciphersService: CiphersService
  ) {
    this.headerService.showInfoPanel.next(false);
    this.analyses = [
      this.ciphersService
        .getCryptanalysis()
        .filter((item) => item.type === 'substitution'),
      this.ciphersService
        .getCryptanalysis()
        .filter((item) => item.type === 'transposition'),
    ];
  }
}
