import { Component } from '@angular/core';
import { ActiveClassificationService } from '../../services/ActiveClassificationService/active-classification-service.service';
import { HeaderService } from "../../components/header/header.service";


@Component({
  selector: 'app-steganography',
  templateUrl: './steganography.component.html',
  styleUrls: ['../../app.component.css']
})
export class SteganographyComponent {
  constructor(
    private activeClassificationService: ActiveClassificationService,
    headerService: HeaderService
  ) {
    headerService.showInfoPanel.next(true);
    headerService.cipherName.next("Steganography");
    headerService.cipherType.next("General description of");
  }
  changeTab(index: number) {
    this.activeClassificationService.setSelectedTab(index);
  }
}
